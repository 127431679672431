import { default as _91_91slug_93_93gnYVCq8tMaMeta } from "/vercel/path0/pages/[[slug]].vue?macro=true";
import { default as _91slug_93o5sFUbf2X6Meta } from "/vercel/path0/pages/events/[slug].vue?macro=true";
import { default as _91slug_93jcSM00X7C7Meta } from "/vercel/path0/pages/legal/[slug].vue?macro=true";
import { default as _91slug_93Dm5SRMWNSjMeta } from "/vercel/path0/pages/news/[slug].vue?macro=true";
import { default as _91slug_938Edb9Y5tVLMeta } from "/vercel/path0/pages/stores/[slug].vue?macro=true";
import { default as developersTB3d6HqXNBMeta } from "/vercel/path0/pages/test/developers.vue?macro=true";
import { default as form2z5F7sOyMpMeta } from "/vercel/path0/pages/test/form.vue?macro=true";
import { default as logos4j5cxdV7mlMeta } from "/vercel/path0/pages/test/logos.vue?macro=true";
import { default as styleguideLr6tN3zsriMeta } from "/vercel/path0/pages/test/styleguide.vue?macro=true";
import { default as _91slug_93Lp5Y2yANY0Meta } from "/vercel/path0/pages/trends/[slug].vue?macro=true";
export default [
  {
    name: _91_91slug_93_93gnYVCq8tMaMeta?.name ?? "slug___nl-be",
    path: _91_91slug_93_93gnYVCq8tMaMeta?.path ?? "/nl-be/:slug?",
    meta: _91_91slug_93_93gnYVCq8tMaMeta || {},
    alias: _91_91slug_93_93gnYVCq8tMaMeta?.alias || [],
    redirect: _91_91slug_93_93gnYVCq8tMaMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93gnYVCq8tMaMeta?.name ?? "slug___fr-be",
    path: _91_91slug_93_93gnYVCq8tMaMeta?.path ?? "/fr-be/:slug?",
    meta: _91_91slug_93_93gnYVCq8tMaMeta || {},
    alias: _91_91slug_93_93gnYVCq8tMaMeta?.alias || [],
    redirect: _91_91slug_93_93gnYVCq8tMaMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93o5sFUbf2X6Meta?.name ?? "events-slug___nl-be",
    path: _91slug_93o5sFUbf2X6Meta?.path ?? "/nl-be/evenementen/:slug",
    meta: _91slug_93o5sFUbf2X6Meta || {},
    alias: _91slug_93o5sFUbf2X6Meta?.alias || [],
    redirect: _91slug_93o5sFUbf2X6Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93o5sFUbf2X6Meta?.name ?? "events-slug___fr-be",
    path: _91slug_93o5sFUbf2X6Meta?.path ?? "/fr-be/evenements/:slug",
    meta: _91slug_93o5sFUbf2X6Meta || {},
    alias: _91slug_93o5sFUbf2X6Meta?.alias || [],
    redirect: _91slug_93o5sFUbf2X6Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jcSM00X7C7Meta?.name ?? "legal-slug___nl-be",
    path: _91slug_93jcSM00X7C7Meta?.path ?? "/nl-be/legal/:slug()",
    meta: _91slug_93jcSM00X7C7Meta || {},
    alias: _91slug_93jcSM00X7C7Meta?.alias || [],
    redirect: _91slug_93jcSM00X7C7Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jcSM00X7C7Meta?.name ?? "legal-slug___fr-be",
    path: _91slug_93jcSM00X7C7Meta?.path ?? "/fr-be/legal/:slug()",
    meta: _91slug_93jcSM00X7C7Meta || {},
    alias: _91slug_93jcSM00X7C7Meta?.alias || [],
    redirect: _91slug_93jcSM00X7C7Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Dm5SRMWNSjMeta?.name ?? "news-slug___nl-be",
    path: _91slug_93Dm5SRMWNSjMeta?.path ?? "/nl-be/nieuws/:slug",
    meta: _91slug_93Dm5SRMWNSjMeta || {},
    alias: _91slug_93Dm5SRMWNSjMeta?.alias || [],
    redirect: _91slug_93Dm5SRMWNSjMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Dm5SRMWNSjMeta?.name ?? "news-slug___fr-be",
    path: _91slug_93Dm5SRMWNSjMeta?.path ?? "/fr-be/nouvelles/:slug",
    meta: _91slug_93Dm5SRMWNSjMeta || {},
    alias: _91slug_93Dm5SRMWNSjMeta?.alias || [],
    redirect: _91slug_93Dm5SRMWNSjMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938Edb9Y5tVLMeta?.name ?? "stores-slug___nl-be",
    path: _91slug_938Edb9Y5tVLMeta?.path ?? "/nl-be/vestigingen/:slug",
    meta: _91slug_938Edb9Y5tVLMeta || {},
    alias: _91slug_938Edb9Y5tVLMeta?.alias || [],
    redirect: _91slug_938Edb9Y5tVLMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/stores/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938Edb9Y5tVLMeta?.name ?? "stores-slug___fr-be",
    path: _91slug_938Edb9Y5tVLMeta?.path ?? "/fr-be/etablissements/:slug",
    meta: _91slug_938Edb9Y5tVLMeta || {},
    alias: _91slug_938Edb9Y5tVLMeta?.alias || [],
    redirect: _91slug_938Edb9Y5tVLMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/stores/[slug].vue").then(m => m.default || m)
  },
  {
    name: developersTB3d6HqXNBMeta?.name ?? "test-developers___nl-be",
    path: developersTB3d6HqXNBMeta?.path ?? "/nl-be/test/developers",
    meta: developersTB3d6HqXNBMeta || {},
    alias: developersTB3d6HqXNBMeta?.alias || [],
    redirect: developersTB3d6HqXNBMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/test/developers.vue").then(m => m.default || m)
  },
  {
    name: developersTB3d6HqXNBMeta?.name ?? "test-developers___fr-be",
    path: developersTB3d6HqXNBMeta?.path ?? "/fr-be/test/developers",
    meta: developersTB3d6HqXNBMeta || {},
    alias: developersTB3d6HqXNBMeta?.alias || [],
    redirect: developersTB3d6HqXNBMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/test/developers.vue").then(m => m.default || m)
  },
  {
    name: form2z5F7sOyMpMeta?.name ?? "test-form___nl-be",
    path: form2z5F7sOyMpMeta?.path ?? "/nl-be/test/form",
    meta: form2z5F7sOyMpMeta || {},
    alias: form2z5F7sOyMpMeta?.alias || [],
    redirect: form2z5F7sOyMpMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/test/form.vue").then(m => m.default || m)
  },
  {
    name: form2z5F7sOyMpMeta?.name ?? "test-form___fr-be",
    path: form2z5F7sOyMpMeta?.path ?? "/fr-be/test/form",
    meta: form2z5F7sOyMpMeta || {},
    alias: form2z5F7sOyMpMeta?.alias || [],
    redirect: form2z5F7sOyMpMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/test/form.vue").then(m => m.default || m)
  },
  {
    name: logos4j5cxdV7mlMeta?.name ?? "test-logos___nl-be",
    path: logos4j5cxdV7mlMeta?.path ?? "/nl-be/test/logos",
    meta: logos4j5cxdV7mlMeta || {},
    alias: logos4j5cxdV7mlMeta?.alias || [],
    redirect: logos4j5cxdV7mlMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/test/logos.vue").then(m => m.default || m)
  },
  {
    name: logos4j5cxdV7mlMeta?.name ?? "test-logos___fr-be",
    path: logos4j5cxdV7mlMeta?.path ?? "/fr-be/test/logos",
    meta: logos4j5cxdV7mlMeta || {},
    alias: logos4j5cxdV7mlMeta?.alias || [],
    redirect: logos4j5cxdV7mlMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/test/logos.vue").then(m => m.default || m)
  },
  {
    name: styleguideLr6tN3zsriMeta?.name ?? "test-styleguide___nl-be",
    path: styleguideLr6tN3zsriMeta?.path ?? "/nl-be/test/styleguide",
    meta: styleguideLr6tN3zsriMeta || {},
    alias: styleguideLr6tN3zsriMeta?.alias || [],
    redirect: styleguideLr6tN3zsriMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/test/styleguide.vue").then(m => m.default || m)
  },
  {
    name: styleguideLr6tN3zsriMeta?.name ?? "test-styleguide___fr-be",
    path: styleguideLr6tN3zsriMeta?.path ?? "/fr-be/test/styleguide",
    meta: styleguideLr6tN3zsriMeta || {},
    alias: styleguideLr6tN3zsriMeta?.alias || [],
    redirect: styleguideLr6tN3zsriMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/test/styleguide.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Lp5Y2yANY0Meta?.name ?? "trends-slug___nl-be",
    path: _91slug_93Lp5Y2yANY0Meta?.path ?? "/nl-be/trends-inspiratie/:slug",
    meta: _91slug_93Lp5Y2yANY0Meta || {},
    alias: _91slug_93Lp5Y2yANY0Meta?.alias || [],
    redirect: _91slug_93Lp5Y2yANY0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/trends/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Lp5Y2yANY0Meta?.name ?? "trends-slug___fr-be",
    path: _91slug_93Lp5Y2yANY0Meta?.path ?? "/fr-be/tendances-inspiration/:slug",
    meta: _91slug_93Lp5Y2yANY0Meta || {},
    alias: _91slug_93Lp5Y2yANY0Meta?.alias || [],
    redirect: _91slug_93Lp5Y2yANY0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/trends/[slug].vue").then(m => m.default || m)
  }
]