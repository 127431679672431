
// @ts-nocheck


export const localeCodes =  [
  "nl-be",
  "fr-be"
]

export const localeMessages = {
  "nl-be": [],
  "fr-be": []
}

export const vueI18nConfigs = [
  () => import("../config/i18n-vue.config.ts?hash=66b1caa9&config=1" /* webpackChunkName: "__config_i18n_vue_config_ts_66b1caa9" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "./server/localeDetector.ts"
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./config/i18n-vue.config.ts",
  "locales": [
    {
      "code": "nl-be",
      "iso": "nl-be",
      "name": "Nederlands"
    },
    {
      "code": "fr-be",
      "iso": "fr-be",
      "name": "Français"
    }
  ],
  "defaultLocale": "nl-be",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://www.spuntini.be",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "events/[slug]": {
      "nl-be": "/evenementen/[slug]",
      "fr-be": "/evenements/[slug]"
    },
    "news/[slug]": {
      "nl-be": "/nieuws/[slug]",
      "fr-be": "/nouvelles/[slug]"
    },
    "stores/[slug]": {
      "nl-be": "/vestigingen/[slug]",
      "fr-be": "/etablissements/[slug]"
    },
    "trends/[slug]": {
      "nl-be": "/trends-inspiratie/[slug]",
      "fr-be": "/tendances-inspiration/[slug]"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const nuxtI18nInternalOptions = {
  "__normalizedLocales": [
    {
      "code": "nl-be",
      "iso": "nl-be",
      "name": "Nederlands",
      "files": []
    },
    {
      "code": "fr-be",
      "iso": "fr-be",
      "name": "Français",
      "files": []
    }
  ]
}

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

