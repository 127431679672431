import revive_payload_client_MBVhALHws2 from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.1_@parcel+watcher@2.3.0_@types+node@20.10.7_encoding@0.1.13_eslint@8.56.0_optionator_lj2gwj3am3gwrvcglpjm3tllyq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_RbZhAWYGsR from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.1_@parcel+watcher@2.3.0_@types+node@20.10.7_encoding@0.1.13_eslint@8.56.0_optionator_lj2gwj3am3gwrvcglpjm3tllyq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Y8DwMnr30l from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.1_@parcel+watcher@2.3.0_@types+node@20.10.7_encoding@0.1.13_eslint@8.56.0_optionator_lj2gwj3am3gwrvcglpjm3tllyq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_CC0DkBKF9a from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.5_@nuxt+devtools@1.0.8_nuxt@3.9.1_@parcel+watcher@2.3.0_@types+node@20.1_ppwggfq2emfimq7zvydivxcd7y/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_A6dKkoW6BH from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.1_@parcel+watcher@2.3.0_@types+node@20.10.7_encoding@0.1.13_eslint@8.56.0_optionator_lj2gwj3am3gwrvcglpjm3tllyq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_aw7URm0C0c from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.1_@parcel+watcher@2.3.0_@types+node@20.10.7_encoding@0.1.13_eslint@8.56.0_optionator_lj2gwj3am3gwrvcglpjm3tllyq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_SM9oXZQKEj from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.1_@parcel+watcher@2.3.0_@types+node@20.10.7_encoding@0.1.13_eslint@8.56.0_optionator_lj2gwj3am3gwrvcglpjm3tllyq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ijlLj1b75y from "/vercel/path0/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.9.1_@parcel+watcher@2.3.0_@types+node@20.10.7_encodin_rtt4ocfw53ufo767vyahuotxwm/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import composition_Tuu9bMCLmm from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.0.0_rollup@4.9.4_vue-router@4.2.5_vue@3.4.13_typescript@5.3.3___vue@3.4.13_typescript@5.3.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_vUDnRksZOM from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.0.0_rollup@4.9.4_vue-router@4.2.5_vue@3.4.13_typescript@5.3.3___vue@3.4.13_typescript@5.3.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import colors_K2tY0XSpsq from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.12.0_change-case@4.1.2_focus-trap@7.5.4_nuxt@3.9.1_@parcel+watcher@2.3.0_@types+no_bmwb2wamjkpylqsx5ez5fuz3mu/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_mz8K7nskeL from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.3.2_rollup@4.9.4/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import view_transitions_client_otnjq1hSLd from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.1_@parcel+watcher@2.3.0_@types+node@20.10.7_encoding@0.1.13_eslint@8.56.0_optionator_lj2gwj3am3gwrvcglpjm3tllyq/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_CZ44IwP6m1 from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.1_@parcel+watcher@2.3.0_@types+node@20.10.7_encoding@0.1.13_eslint@8.56.0_optionator_lj2gwj3am3gwrvcglpjm3tllyq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import sentry_client_shVUlIjFLk from "/vercel/path0/plugins/sentry.client.ts";
export default [
  revive_payload_client_MBVhALHws2,
  unhead_RbZhAWYGsR,
  router_Y8DwMnr30l,
  _0_siteConfig_CC0DkBKF9a,
  payload_client_A6dKkoW6BH,
  check_outdated_build_client_aw7URm0C0c,
  components_plugin_KR1HBZs4kY,
  prefetch_client_SM9oXZQKEj,
  plugin_ijlLj1b75y,
  composition_Tuu9bMCLmm,
  i18n_vUDnRksZOM,
  colors_K2tY0XSpsq,
  plugin_client_mz8K7nskeL,
  view_transitions_client_otnjq1hSLd,
  chunk_reload_client_CZ44IwP6m1,
  sentry_client_shVUlIjFLk
]