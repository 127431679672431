<script setup lang="ts">
// FALLBACK
useHead({
  title: "Spuntini",
});
</script>

<template>
  <NuxtLoadingIndicator color="#C81414" />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
