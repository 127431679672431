const _inherit = "inherit"
const _current = "currentColor"
const _transparent = "transparent"
const _black = "#000"
const _white = "#fff"
const _slate = {"50":"#f8fafc","100":"#f1f5f9","200":"#e2e8f0","300":"#cbd5e1","400":"#94a3b8","500":"#64748b","600":"#475569","700":"#334155","800":"#1e293b","900":"#0f172a","950":"#020617"}
const _gray = {"50":"rgb(var(--color-gray-50) / <alpha-value>)","100":"rgb(var(--color-gray-100) / <alpha-value>)","200":"rgb(var(--color-gray-200) / <alpha-value>)","300":"rgb(var(--color-gray-300) / <alpha-value>)","400":"rgb(var(--color-gray-400) / <alpha-value>)","500":"rgb(var(--color-gray-500) / <alpha-value>)","600":"rgb(var(--color-gray-600) / <alpha-value>)","700":"rgb(var(--color-gray-700) / <alpha-value>)","800":"rgb(var(--color-gray-800) / <alpha-value>)","900":"rgb(var(--color-gray-900) / <alpha-value>)","950":"rgb(var(--color-gray-950) / <alpha-value>)"}
const _zinc = {"50":"#fafafa","100":"#f4f4f5","200":"#e4e4e7","300":"#d4d4d8","400":"#a1a1aa","500":"#71717a","600":"#52525b","700":"#3f3f46","800":"#27272a","900":"#18181b","950":"#09090b"}
const _neutral = {"50":"#fafafa","100":"#f5f5f5","200":"#e5e5e5","300":"#d4d4d4","400":"#a3a3a3","500":"#737373","600":"#525252","700":"#404040","800":"#262626","900":"#171717","950":"#0a0a0a"}
const _stone = {"50":"#fafaf9","100":"#f5f5f4","200":"#e7e5e4","300":"#d6d3d1","400":"#a8a29e","500":"#78716c","600":"#57534e","700":"#44403c","800":"#292524","900":"#1c1917","950":"#0c0a09"}
const _red = {"50":"#fef2f2","100":"#fee2e2","200":"#fecaca","300":"#fca5a5","400":"#f87171","500":"#ef4444","600":"#dc2626","700":"#b91c1c","800":"#991b1b","900":"#7f1d1d","950":"#450a0a"}
const _orange = {"50":"#fff7ed","100":"#ffedd5","200":"#fed7aa","300":"#fdba74","400":"#fb923c","500":"#f97316","600":"#ea580c","700":"#c2410c","800":"#9a3412","900":"#7c2d12","950":"#431407"}
const _amber = {"50":"#fffbeb","100":"#fef3c7","200":"#fde68a","300":"#fcd34d","400":"#fbbf24","500":"#f59e0b","600":"#d97706","700":"#b45309","800":"#92400e","900":"#78350f","950":"#451a03"}
const _yellow = {"50":"#fefce8","100":"#fef9c3","200":"#fef08a","300":"#fde047","400":"#facc15","500":"#eab308","600":"#ca8a04","700":"#a16207","800":"#854d0e","900":"#713f12","950":"#422006"}
const _lime = {"50":"#f7fee7","100":"#ecfccb","200":"#d9f99d","300":"#bef264","400":"#a3e635","500":"#84cc16","600":"#65a30d","700":"#4d7c0f","800":"#3f6212","900":"#365314","950":"#1a2e05"}
const _green = {"50":"#f0fdf4","100":"#dcfce7","200":"#bbf7d0","300":"#86efac","400":"#4ade80","500":"#22c55e","600":"#16a34a","700":"#15803d","800":"#166534","900":"#14532d","950":"#052e16"}
const _emerald = {"50":"#ecfdf5","100":"#d1fae5","200":"#a7f3d0","300":"#6ee7b7","400":"#34d399","500":"#10b981","600":"#059669","700":"#047857","800":"#065f46","900":"#064e3b","950":"#022c22"}
const _teal = {"50":"#f0fdfa","100":"#ccfbf1","200":"#99f6e4","300":"#5eead4","400":"#2dd4bf","500":"#14b8a6","600":"#0d9488","700":"#0f766e","800":"#115e59","900":"#134e4a","950":"#042f2e"}
const _cyan = {"50":"#ecfeff","100":"#cffafe","200":"#a5f3fc","300":"#67e8f9","400":"#22d3ee","500":"#06b6d4","600":"#0891b2","700":"#0e7490","800":"#155e75","900":"#164e63","950":"#083344"}
const _sky = {"50":"#f0f9ff","100":"#e0f2fe","200":"#bae6fd","300":"#7dd3fc","400":"#38bdf8","500":"#0ea5e9","600":"#0284c7","700":"#0369a1","800":"#075985","900":"#0c4a6e","950":"#082f49"}
const _blue = {"50":"#eff6ff","100":"#dbeafe","200":"#bfdbfe","300":"#93c5fd","400":"#60a5fa","500":"#3b82f6","600":"#2563eb","700":"#1d4ed8","800":"#1e40af","900":"#1e3a8a","950":"#172554"}
const _indigo = {"50":"#eef2ff","100":"#e0e7ff","200":"#c7d2fe","300":"#a5b4fc","400":"#818cf8","500":"#6366f1","600":"#4f46e5","700":"#4338ca","800":"#3730a3","900":"#312e81","950":"#1e1b4b"}
const _violet = {"50":"#f5f3ff","100":"#ede9fe","200":"#ddd6fe","300":"#c4b5fd","400":"#a78bfa","500":"#8b5cf6","600":"#7c3aed","700":"#6d28d9","800":"#5b21b6","900":"#4c1d95","950":"#2e1065"}
const _purple = {"50":"#faf5ff","100":"#f3e8ff","200":"#e9d5ff","300":"#d8b4fe","400":"#c084fc","500":"#a855f7","600":"#9333ea","700":"#7e22ce","800":"#6b21a8","900":"#581c87","950":"#3b0764"}
const _fuchsia = {"50":"#fdf4ff","100":"#fae8ff","200":"#f5d0fe","300":"#f0abfc","400":"#e879f9","500":"#d946ef","600":"#c026d3","700":"#a21caf","800":"#86198f","900":"#701a75","950":"#4a044e"}
const _pink = {"50":"#fdf2f8","100":"#fce7f3","200":"#fbcfe8","300":"#f9a8d4","400":"#f472b6","500":"#ec4899","600":"#db2777","700":"#be185d","800":"#9d174d","900":"#831843","950":"#500724"}
const _rose = {"50":"#fff1f2","100":"#ffe4e6","200":"#fecdd3","300":"#fda4af","400":"#fb7185","500":"#f43f5e","600":"#e11d48","700":"#be123c","800":"#9f1239","900":"#881337","950":"#4c0519"}
const _bakery = {"50":"#C8A76E33","100":"#C8A76E33","200":"#C8A76E66","300":"#C8A76E99","400":"#C8A76ECC","500":"#C8A76E","600":"#C8A76E","700":"#C8A76E","800":"#C8A76E","900":"#C8A76E","950":"#C8A76E","DEFAULT":"#C8A76E"}
const _dairy = {"50":"#73B7B633","100":"#73B7B633","200":"#73B7B666","300":"#73B7B699","400":"#73B7B6CC","500":"#73B7B6","600":"#73B7B6","700":"#73B7B6","800":"#73B7B6","900":"#73B7B6","950":"#73B7B6","DEFAULT":"#73B7B6"}
const _drinks = {"50":"#D2A3AB33","100":"#D2A3AB33","200":"#D2A3AB66","300":"#D2A3AB99","400":"#D2A3ABCC","500":"#D2A3AB","600":"#D2A3AB","700":"#D2A3AB","800":"#D2A3AB","900":"#D2A3AB","950":"#D2A3AB","DEFAULT":"#D2A3AB"}
const _dry = {"50":"#638F8C33","100":"#638F8C33","200":"#638F8C66","300":"#638F8C99","400":"#638F8CCC","500":"#638F8C","600":"#638F8C","700":"#638F8C","800":"#638F8C","900":"#638F8C","950":"#638F8C","DEFAULT":"#638F8C"}
const _fruit = {"50":"#A3B55833","100":"#A3B55833","200":"#A3B55866","300":"#A3B55899","400":"#A3B558CC","500":"#A3B558","600":"#A3B558","700":"#A3B558","800":"#A3B558","900":"#A3B558","950":"#A3B558","DEFAULT":"#A3B558"}
const _meat = {"50":"#92BED233","100":"#92BED233","200":"#92BED266","300":"#92BED299","400":"#92BED2CC","500":"#92BED2","600":"#92BED2","700":"#92BED2","800":"#92BED2","900":"#92BED2","950":"#92BED2","DEFAULT":"#92BED2"}
const _non = {"50":"#EAB73233","100":"#EAB73233","200":"#EAB73266","300":"#EAB73299","400":"#EAB732CC","500":"#EAB732","600":"#EAB732","700":"#EAB732","800":"#EAB732","900":"#EAB732","950":"#EAB732","DEFAULT":"#EAB732"}
const _sauces = {"50":"#DD952933","100":"#DD952933","200":"#DD952966","300":"#DD952999","400":"#DD9529CC","500":"#DD9529","600":"#DD9529","700":"#DD9529","800":"#DD9529","900":"#DD9529","950":"#DD9529","DEFAULT":"#DD9529"}
const _snacks = {"50":"#C85F8233","100":"#C85F8233","200":"#C85F8266","300":"#C85F8299","400":"#C85F82CC","500":"#C85F82","600":"#C85F82","700":"#C85F82","800":"#C85F82","900":"#C85F82","950":"#C85F82","DEFAULT":"#C85F82"}
const _primary = {"50":"rgb(var(--color-primary-50) / <alpha-value>)","100":"rgb(var(--color-primary-100) / <alpha-value>)","200":"rgb(var(--color-primary-200) / <alpha-value>)","300":"rgb(var(--color-primary-300) / <alpha-value>)","400":"rgb(var(--color-primary-400) / <alpha-value>)","500":"rgb(var(--color-primary-500) / <alpha-value>)","600":"rgb(var(--color-primary-600) / <alpha-value>)","700":"rgb(var(--color-primary-700) / <alpha-value>)","800":"rgb(var(--color-primary-800) / <alpha-value>)","900":"rgb(var(--color-primary-900) / <alpha-value>)","950":"rgb(var(--color-primary-950) / <alpha-value>)","DEFAULT":"rgb(var(--color-primary-DEFAULT) / <alpha-value>)"}
const _cool = {"50":"#f9fafb","100":"#f3f4f6","200":"#e5e7eb","300":"#d1d5db","400":"#9ca3af","500":"#6b7280","600":"#4b5563","700":"#374151","800":"#1f2937","900":"#111827","950":"#030712"}
const config = { "inherit": _inherit, "current": _current, "transparent": _transparent, "black": _black, "white": _white, "slate": _slate, "gray": _gray, "zinc": _zinc, "neutral": _neutral, "stone": _stone, "red": _red, "orange": _orange, "amber": _amber, "yellow": _yellow, "lime": _lime, "green": _green, "emerald": _emerald, "teal": _teal, "cyan": _cyan, "sky": _sky, "blue": _blue, "indigo": _indigo, "violet": _violet, "purple": _purple, "fuchsia": _fuchsia, "pink": _pink, "rose": _rose, "bakery": _bakery, "dairy": _dairy, "drinks": _drinks, "dry": _dry, "fruit": _fruit, "meat": _meat, "non": _non, "sauces": _sauces, "snacks": _snacks, "primary": _primary, "cool": _cool, "th-primary": {"50":"#F9E7E7","100":"#F4D0D0","200":"#E9A1A1","300":"#DE7272","400":"#D34343","500":"#C81414","600":"#C81414","700":"#C81414","800":"#C81414","900":"#C81414","950":"#C81414","DEFAULT":"#C81414"}, "th-neutral": {"50":"#EBE9E9","100":"#D8D4D4","200":"#B1A9A9","300":"#8A7E7E","400":"#635353","500":"#3C2828","600":"#3C2828","700":"#3C2828","800":"#3C2828","900":"#3C2828","950":"#3C2828","DEFAULT":"#3C2828"},  }
export { config as default, _inherit, _current, _transparent, _black, _white, _slate, _gray, _zinc, _neutral, _stone, _red, _orange, _amber, _yellow, _lime, _green, _emerald, _teal, _cyan, _sky, _blue, _indigo, _violet, _purple, _fuchsia, _pink, _rose, _bakery, _dairy, _drinks, _dry, _fruit, _meat, _non, _sauces, _snacks, _primary, _cool }