export default defineAppConfig({
  ui: {
    primary: "th-primary",
    colors: ["th-neutral", "bakery", "dairy", "drinks", "dry", "fruit", "meat", "non", "sauces", "snacks"],
    badge: {
      strategy: "override",
      font: "font-semibold",
      rounded: "rounded-2xl",
      size: { xs: "text-xs px-xs py-xs leading-4" },
      variant: { solid: "bg-white text-primary" },
    },
    button: {
      strategy: "override",
      base: "flex flex-wrap justify-center transition",
      rounded: "rounded-full",
      color: {
        black: {
          solid: "bg-th-neutral-500 hover:bg-th-neutral-400",
        },
      },
      size: {
        xs: "text-xs px-sm py-xs",
        sm: "text-sm px-sm py-xs",
        md: "text-md md:text-lg px-md md:px-lg py-sm md:py-md",
      },
      variant: {
        solid: "border-{color}-500 bg-{color}-500 hover:bg-{color}-400 text-white",
        outline: "border-{color}-500 bg-transparent hover:bg-{color}-50 text-{color}-500",
      },
    },
    input: {
      strategy: "override",
      base: "relative block w-full border-2 border-th-neutral-100 font-medium disabled:cursor-not-allowed disabled:opacity-75 focus:outline-none",
      variant: {
        none: "bg-white focus:border-non focus:ring-0 focus:shadow-none",
      },
      default: {
        size: "xl",
        variant: "none",
      },
    },
    pagination: {
      strategy: "override",
      base: "border-0 ring-0 shadow-none size-10 flex justify-center",
      rounded: "rounded-md first-of-type:rounded-md last-of-type:rounded-md",
    },
  },
});
